import React from "react";
import {
  Center,
  Box,
  Flex,
  Spinner,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import ProfileCard from "./ProfileCard";
import animation from "./_animation";

export default function UserProfile({ accountResponse }) {
  if (!accountResponse) {
    return null;
  }

  const { account } = accountResponse;

  return (
    <PageContainer lessWidth={true}>
      <Box>
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color="dark.100"
          fontWeight={"700"}
          textTransform={"uppercase"}
          mb={"4"}
        >
          Profile Details
        </Text>

        <List spacing={2}>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              First name:
            </Text>{" "}
            {account.idTokenClaims.given_name}
          </ListItem>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Last name:
            </Text>{" "}
            {account.idTokenClaims.family_name}
          </ListItem>
          <ListItem>
            <Text as={"span"} fontWeight={"bold"}>
              Username:
            </Text>{" "}
            {account.idTokenClaims.name}
          </ListItem>
        </List>
      </Box>
    </PageContainer>
  );
}
