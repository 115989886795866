import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

function AssetMenu({ assets, onSelectAsset }) {
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="outline" colorScheme="teal" size="lg">
          Assets <ChevronDownIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Accordion allowToggle>
            {assets.map((asset) => (
              <AccordionItem key={asset.ID}>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      {asset.Name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box>
                    <Link
                      color="teal.500"
                      href="#"
                      onClick={(e) => onSelectAsset(e, asset.ID, "asset_profile")}
                    >
                      Asset profile
                    </Link>
                  </Box>
                  <Box>
                    <Link
                      color="teal.500"
                      href="#"
                      onClick={(e) =>
                        onSelectAsset(e, asset.ID, "asset_documents")
                      }
                    >
                      Asset documents
                    </Link>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default AssetMenu;
