import React from "react";
import { useTranslation } from "../../../core/hooks/useTranslation";

import Profile from "../Profile";

function Funds({ location }) {
  const { language, t } = useTranslation();

  return (
    <div>
      <Profile location={location} />
    </div>
  );
}

export default Funds;
