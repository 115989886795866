import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";
import { useAuth } from "../core/hooks/useAuth";
import { navigate } from "gatsby";

import Hero from "../views/Funds/Hero";
import Funds from "../views/Funds/Funds";

function FundsPage() {
  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <Funds />
      </AnimatedContent>
    </Box>
  );
}

export default FundsPage;
