import { PublicClientApplication, EventType } from "@azure/msal-browser";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Center, Box, Flex, Spinner } from "@chakra-ui/react";
import PageContainer from "../../../components/PageContainer";
import { msalConfig } from "../../../config/authConfig";
import { navigate } from "gatsby";
import { MsalProvider, useMsal } from "@azure/msal-react";

import AssetMenu from "./AssetMenu";
import AssetProfile from "./AssetProfile";
import FundMenu from "./FundMenu";
import FundProfile from "./FundProfile";
import FundAssets from "./FundAssets";
import UserProfile from "./UserProfile";
import Documents from "./Documents";

const msalInstance = new PublicClientApplication(msalConfig);

const [account] = msalInstance.getAllAccounts();

const BASE_PATH =
  process.env.NODE_ENV === "production"
    ? "https://18azovgv7b.execute-api.us-east-1.amazonaws.com/staging"
    : "http://localhost:3000";

function RootProfile() {
  return (
    <MsalProvider instance={msalInstance}>
      <Profile />
    </MsalProvider>
  );
}

function Profile() {
  const [userData, setUserData] = useState([]);
  const [accountResponse, setAccountResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewName, setViewName] = useState("user_profile");
  const [fundId, setFundId] = useState(null);
  const { accounts } = useMsal();

  if (!msalInstance || !account) {
    navigate("/");
  }

  useEffect(async () => {
    if (accounts && accounts.length > 0) {
      const accountResponse = await msalInstance.acquireTokenSilent({
        scopes: ["openid", "profile"], // Add any additional scopes as needed
        account,
      });

      try {
        const resp = await axios.get(
          `${BASE_PATH}/user?idToken=${accountResponse.idToken}`
        );
        setLoading(false);

        setUserData(resp.data.userData);
        setAccountResponse(accountResponse);
      } catch (err) {
        console.log(err);
        msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
      }
    }
  }, []);

  if (loading) {
    return (
      <Center h="300px">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (userData.length === 0) {
    return null;
  }

  const handleProfileClick = () => {
    setViewName("user_profile");
  };

  const handleMenuClick = (e, fundId, viewName) => {
    e.preventDefault();
    setViewName(viewName);
    setFundId(fundId);
  };

  const funds = userData.funds.map((item) => item.fund);
  const assets = userData.assets.map((item) => item.asset);

  const renderViews = () => {
    if (viewName === "user_profile") {
      return <UserProfile accountResponse={accountResponse} />;
    } else if (viewName === "fund_profile") {
      const fund = funds.find((fund) => fund.ID === fundId);
      return <FundProfile fund={fund} />;
    } else if (viewName === "fund_documents") {
      const fund = funds.find((fund) => fund.ID === fundId);
      return (
        <Documents
          name="Fund"
          attachments={fund.attachments}
          idToken={accountResponse.idToken}
        />
      );
    } else if (viewName === "fund_assets") {
      const fund = funds.find((fund) => fund.ID === fundId);
      return <FundAssets fund={fund} />;
    } else if (viewName === "asset_documents") {
      const asset = assets.find((asset) => asset.ID === fundId);
      return (
        <Documents
          name="Asset"
          attachments={asset.attachments}
          idToken={accountResponse.idToken}
        />
      );
    } else if (viewName === "asset_profile") {
      const asset = assets.find((asset) => asset.ID === fundId);
      const fund = funds.find((fund) => fund.ID === asset.RelatedFundId);
      return <AssetProfile asset={asset} fund={fund} />;
    }
  };

  return (
    <>
      <Box
        paddingTop="md"
        paddingBottom={{ base: "md", md: "viewFundsProfileB" }}
      >
        <PageContainer lessWidth={true}>
          <Box
            paddingTop="viewFundsProfileT"
            paddingBottom={{ base: "md", md: "viewFundsProfileT" }}
          >
            <Flex justifyContent="space-between">
              <Button
                variant="outline"
                colorScheme="teal"
                size="lg"
                onClick={handleProfileClick}
              >
                Profile
              </Button>
              <FundMenu funds={funds} onSelectFund={handleMenuClick} />
              <AssetMenu assets={assets} onSelectAsset={handleMenuClick} />
            </Flex>
          </Box>
        </PageContainer>
        {renderViews()}
      </Box>
    </>
  );
}

export default RootProfile;
