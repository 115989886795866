import React from "react";
import { Center, Box, Flex, Spinner } from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import ProfileCard from "./ProfileCard";
import animation from "./_animation";

export default function FundAssets({ fund }) {
  return (
    <>
      <PageContainer lessWidth={true}>
        <ContentTitle
          titleBold="Fund"
          titleLight="Underlying Assets"
          order="inline"
          spaces="0"
          keepOrder={true}
        />
      </PageContainer>
      <Box marginTop="viewFundsProfileAreaT">
        <PageContainer lessWidth={true}>
          {fund.assets.map((asset, i) => (
            <Box p="5" key={asset.ID}>
              <Flex justifyContent="space-between">
                <Box>Asset name</Box>
                <Box>{asset.Name}</Box>
              </Flex>
              <Flex justifyContent="space-between">
                <Box>Related fund</Box>
                <Box>{fund.Name}</Box>
              </Flex>
              {/* TODO: add whenever asset owner is ready */}
              {/* <Flex key={asset.ID} justifyContent="space-between">
                <Box>Asset owner</Box>
                <Box key={asset.ID}>{asset.Name}</Box>
              </Flex> */}
            </Box>
          ))}
        </PageContainer>
      </Box>
    </>
  );
}
