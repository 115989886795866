import React from "react";
import { Center, Box, Flex, Spinner } from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import ProfileCard from "./ProfileCard";
import animation from "./_animation";

export default function FundProfile({ fund }) {
  const profileCardData = {
    left: {
      type: "table",
      title: fund.Name,
      rows: [
        { text: "Type of Fund", value: fund.FundType },
        { text: "Domiciliation", value: fund.Domiciliation },
        { text: "Term of Fund", value: fund.Term },
        {
          text: "Base Currency",
          value: fund.InitialInvestmentCurrency,
        },
        {
          text: "Nav calculation frequency",
          value: `${fund.DistributionPolicy}, ${fund.NavFrequency}`,
        },
        { text: "Expected target annual", value: fund.ExpectedReturn },
        {
          text: "Max number of participants",
          value: fund.MaxNumberParticipants,
        },
      ],
    },
    right: {
      type: "row",
      title: "Minimum Initial Investment",
      rows: [fund.MinInitialInvestment],
    },
  };

  const secondCardData = {
    left: {
      type: "table",
      title: "Investment Strategy",
      rows: [
        { text: "Management fee", value: "2% of Nov" },
        { text: "Performance fee", value: "20% voer a 20% hurdle rage" },
        { text: "Tax rate", value: "0.01% of annual Nav" },
      ],
    },
    right: {
      type: "table",
      title: "Service providers",
      rows: [
        {
          text: "Management",
          value: "Berkeley Capital CJSC",
        },
        { text: "Fund Register", value: "Central Bank of Armenia" },
        { text: "Account Operator", value: "AN Audit" },
        { text: "Fund Asset Cistodian", value: "Ardshinbank CJSC" },
      ],
    },
  };

  return (
    <>
      <PageContainer lessWidth={true}>
        <ContentTitle
          titleBold="Fund"
          titleLight="Profile"
          order="inline"
          spaces="0"
          keepOrder={true}
        />
      </PageContainer>
      <Box marginTop="viewFundsProfileAreaGradMT">
        <Box position="relative">
          <Box
            position="relative"
            zIndex="2"
            paddingY="viewFundsProfileAreaGradP"
          >
            <PageContainer>
              <ProfileCard {...profileCardData} color="white" />
            </PageContainer>
          </Box>

          <Box
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            zIndex="1"
            width={{ base: "100%", lg: "viewFundsProfileAreaGradW" }}
            height="100%"
          >
            <ViewportAnimation {...animation.gradient}>
              <Box
                borderTopRightRadius={{ base: 0, lg: "bigCard" }}
                borderBottomRightRadius={{ base: 0, lg: "bigCard" }}
                bgGradient="linear(90deg, blue.1000 0%, blue.1100 100%)"
                width={{ base: "100%", lg: "viewFundsProfileAreaGradW" }}
                height="100%"
              />
            </ViewportAnimation>
          </Box>
        </Box>

        <Box marginTop="viewFundsProfileAreaT">
          <PageContainer>
            <ViewportAnimation {...animation.content}>
              <ProfileCard {...secondCardData} color="black" />
            </ViewportAnimation>
          </PageContainer>
        </Box>
      </Box>
    </>
  );
}
