import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import ProfileCardCell from "./ProfileCardCell";

function ProfileCard({ left, right, color }) {
  return (
    <Flex
      justifyContent="space-between"
      flexDirection={{ base: "column", xl: "row" }}
      maxWidth={{ base: "100%", lg: "70%", xl: "viewFundsProfileCardMaxW" }}
      minHeight="viewFundsProfileCardMinH"
      width={{ base: "viewFundsProfileAreaGradW", xl: "100%" }}
      height="100%"
      margin={{ base: "", xl: "0 auto" }}
    >
      <Box
        height="100%"
        width={{ base: "100%", xl: "calc(50% - 2px)" }}
        maxWidth={{ base: "100%", xl: "viewFundsProfileCardCellMinW" }}
      >
        <ProfileCardCell {...left} color={color} />
      </Box>

      <Box
        minHeight={{ base: "1px", xl: "100%" }}
        width={{ base: "100%", xl: "1px" }}
        marginY={{ base: "3xl", xl: "0" }}
        border="1px solid"
        borderColor={color}
        backgroundColor={color}
        opacity={"white" === color ? 0.5 : 0.25}
      />

      <Box
        height="100%"
        width={{ base: "100%", xl: "calc(50% - 2px)" }}
        maxWidth={{ base: "100%", xl: "viewFundsProfileCardCellMinW" }}
      >
        <ProfileCardCell {...right} color={color} />
      </Box>
    </Flex>
  );
}

export default ProfileCard;
