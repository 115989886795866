const contentAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-3rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.75,
    delay: 0.73,
  },
};

const gradientAnimation = {
  style: {
    position: "relative",
    height: "100%",
  },
  initial: {
    opacity: 0,
    top: "-1.2rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.91,
    delay: 0.25,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  content: contentAnimation,
  gradient: gradientAnimation,
};
