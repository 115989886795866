import React from "react";
import { Box, Heading, Table, Tbody, Tr, Td } from "@chakra-ui/react";

function ProfileCardCell({ type, title, rows, color }) {
  return (
    <Box>
      <Heading
        color={color}
        fontWeight="bold"
        fontSize="md"
        marginBottom="viewFundsProfileTableT"
      >
        {title}
      </Heading>

      <Table display={{ base: "block", md: "table" }}>
        <Tbody display={{ base: "block", md: "table-row-group" }}>
          {rows.map((row, i) => (
            <Tr
              key={`funds-row-${title}-${type}-${i}`}
              display={{ base: "block", md: "table-row" }}
            >
              {"row" === type ? (
                <Td
                  padding="0"
                  border="none"
                  color={color}
                  fontWeight="light"
                  fontSize="1.5sm"
                  paddingTop="0"
                  paddingBottom="viewFundsProfileRowB"
                >
                  {row}
                </Td>
              ) : (
                <>
                  <Td
                    paddingTop="0"
                    paddingX="0"
                    paddingBottom={{ base: "lg", md: "viewFundsProfileRowB" }}
                    border="none"
                    color={color}
                    fontWeight="Bold"
                    fontSize="1.5sm"
                    display={{ base: "block", md: "table-cell" }}
                  >
                    {row.text}
                  </Td>
                  <Td
                    paddingTop="0"
                    paddingX="0"
                    paddingBottom="viewFundsProfileRowB"
                    border="none"
                    color={color}
                    fontWeight="light"
                    fontSize="1.5sm"
                    textAlign={{ base: "left", md: "right" }}
                    display={{ base: "block", md: "table-cell" }}
                  >
                    {row.value}
                  </Td>
                </>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default ProfileCardCell;
