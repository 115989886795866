import React from "react";
import axios from "axios";
import { Box, Flex } from "@chakra-ui/react";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";

const BASE_PATH =
  process.env.NODE_ENV === "production"
    ? "https://18azovgv7b.execute-api.us-east-1.amazonaws.com/staging"
    : "http://localhost:3000";

export default function Documents({ name, attachments, idToken }) {
  return (
    <>
      <PageContainer lessWidth={true}>
        <ContentTitle
          titleBold={name}
          titleLight="Documents"
          order="inline"
          spaces="0"
          keepOrder={true}
        />
        <Box marginTop="viewFundsProfileAreaGradMT">
          {attachments.map((attachment) => (
            <Box
              style={{ cursor: "pointer" }}
              key={attachment.id}
              onClick={() => {
                axios
                  .post(`${BASE_PATH}/attachment`, {
                    fileName: attachment.FileName,
                    url: attachment.__metadata.uri,
                    idToken,
                  })
                  .then((response) => {
                    // console.log(respo)
                    // create file link in browser's memory
                    // const href = URL.createObjectURL(response.data);
                    const href =
                      "data:application/octet-stream;base64," + response.data;

                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", attachment.FileName);
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                  });
              }}
            >
              {attachment.FileName}
            </Box>
          ))}
        </Box>
      </PageContainer>
    </>
  );
}
